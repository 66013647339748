import * as React from "react";

import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { hasKeys, hasGeometry, isPoint, getIconStyle } from "../../utils";

import DrawControl from './DrawControl';
import LayerControl, { MAP_STYLES } from './LayerControl';
import { FITBOUNDS_OPTIONS, OUTLINE_STYLE, EMPTY_GEOJSON } from './MapConstants';

import area from "@turf/area";
import bbox from "@turf/bbox";
import centroid from "@turf/centroid";
import Map, { Source, Layer, NavigationControl } from 'react-map-gl';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "mapbox-gl-style-switcher/styles.css";

import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveIcon from '@mui/icons-material/Remove';


const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        '& .maplibregl-ctrl-attrib': {
            padding: '2px 24px 2px 0px',
        },
        '& .maplibregl-ctrl-attrib[open]': {
            padding: '2px 28px 2px 8px'
        },
        '& .maplibregl-ctrl-attrib-button': {
          bottom: 0,
          top: 'auto'
        },
        '& .maplibregl-ctrl-attrib-button:focus': {
          boxShadow: 'none'
        },
        '& button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon': {
            backgroundImage: getIconStyle(<AddIcon />)
        },
        '& button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon': {
            backgroundImage: getIconStyle(<RemoveIcon />)
        },
        '& .mapbox-gl-draw_ctrl-draw-btn.active, .mapbox-gl-draw_ctrl-draw-btn.active:hover': {
          backgroundColor: 'rgb(0 0 0/20%)'
        },
        '& button.mapbox-gl-draw_polygon': { 
            backgroundImage: getIconStyle(<ModeEditIcon />)
        },
        '& button.mapbox-gl-draw_rectangle': { 
            backgroundImage: getIconStyle(<HighlightAltIcon />)
        },
        '& button.mapbox-gl-draw_trash': {
            backgroundImage: getIconStyle(<ClearIcon />)
        },
        '& .maplibregl-ctrl-bottom-left': {
            bottom: 12,
        },
        '& .maplibregl-ctrl-bottom-right': {
            bottom: 12,
        },
    },
    overlay: {
        position: "absolute",
        display: "flex",
        alignContent: "end",
        bottom: 0,
        right: 0,
        padding: 2,
        background: '#ffffff',
        opacity: 0.65,
        maxWidth: '420px !important',
        width: '100%',
        minHeight: 12,
    },
    overlayText: {
        textAlign: "right",
        fontSize: '0.6em',
        padding: 3,
        fontFamily: "sans-serif",
        lineHeight: 1,
        color: "#323232",
        opacity: 1,
        '& > a': {
            textDecoration: "none",
        }
    }
}));
      
export default function PlaceDisplay(props) {
  const { width = 800, height = 500, geojson_url, clear, onClear, onDraw, onLoad, onError } = props;
  const mapRef = React.useRef();
  const drawRef = React.useRef();
  const attributionRef = React.useRef();

  let mapSource;
  if (mapRef.current) {
    mapSource = mapRef.current.getSource('wof');
    mapRef.current.once('idle', function () {
      mapRef.current.resize()
      if (!attributionRef.current) {
        let attributionControl = new maplibregl.AttributionControl({
          compact: true
        })
        mapRef.current.addControl(attributionControl);
        attributionControl._toggleAttribution(); // default to closed
        attributionControl._container.removeAttribute('open'); // remove open attribute that causes extra padding
        attributionRef.current = attributionControl;
      }
    });
  }


  const classes = useStyles();
  const [features, setFeatures] = React.useState(EMPTY_GEOJSON);

  const onClearFeatures = React.useCallback(() => {
    mapRef.current.getSource('wof')?.setData(EMPTY_GEOJSON);
    setFeatures(EMPTY_GEOJSON);
    if(onClear) { onClear(); }
  }, [onClear])

  React.useEffect(() => {
    if(clear) {
      onClearFeatures();
    }
  }, [clear, onClearFeatures])

  React.useEffect(() => {
    if(!geojson_url || !typeof(geojson_url) === "string" || !geojson_url.startsWith('http')) {
      // this is not a change we need to fetch for
      return;
    }
    fetch(geojson_url)
      .then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else {
            throw new Error("Unable to get geojson url")
        }
      })
      .then(resultData => {
        // use faster hasGeometry here, not full turf/booleanValid
        if (hasKeys(resultData) && (isPoint(resultData) || hasGeometry(resultData))) {
            setFeatures(resultData);
        } else {
            throw new Error("Invalid geojson file");
        }
      })
      .catch(e => {
        console.error(e)
        if(onError) { onError(e) }
        return 
      })
      // skip onError from the dependency list, it uses react-hook-forms setValue, and should be considered safe
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geojson_url])

  React.useEffect(() => {
    if (!hasKeys(features)) {
        return () => {
            if(mapSource) {
                mapSource.setData(EMPTY_GEOJSON);
            }
        };
    }
    if (features === EMPTY_GEOJSON) {
      onLoad(features);
      return;
    }
    if (isPoint(features) || hasGeometry(features)) {
        if (mapRef.current) {
            const currentBbox = features.bbox || bbox(features);
            if (currentBbox.length === 4) {
              mapRef.current.fitBounds(currentBbox, FITBOUNDS_OPTIONS);
            }
            if (mapSource) {
                mapSource.setData(features);
            }
            if (attributionRef.current) {
              attributionRef.current._container.removeAttribute('open'); // default to closed
            }
        } else {
            if(onError) { onError({message: 'No map loaded, cannot fit bounds'}) }
        }
        if(isPoint(features) && onError) {
          onError({message: `Feature ${features.id} is Point, not Polygon`})
        }
        if(onLoad) { onLoad(features) }
    } else {
        if(onError) { onError({message: 'Invalid geojson data'}) }
    }
    // skip onLoad from the dependency list, it uses react-hook-forms setValue, and should be considered safe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [features]);

  const onDrawUpdate = React.useCallback(e => {
    // only allow one drawn feature
    // replace existing features with the drawn ones
    const newFeatures = e.features[0];
    if (hasGeometry(newFeatures)) {
      let geom_centroid = centroid(newFeatures);
      newFeatures.properties['geom:area_square_m'] = area(newFeatures);
      newFeatures.properties['geom:longitude'] = geom_centroid.geometry.coordinates[0];
      newFeatures.properties['geom:latitude'] = geom_centroid.geometry.coordinates[1];
      setFeatures(newFeatures);
      if(onDraw) { onDraw(newFeatures) }
    } else {
      if(onError) { onError({message: 'Invalid drawn feature'})};
    }
  }, [onDraw, onError])

  const onDrawDelete = React.useCallback(e => {
    setFeatures(currFeatures => {
      const newFeatures = {...currFeatures};
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  return (<Box className={classes.root}>
    <Map
        ref={mapRef}
        className={classes.map}
        initialViewState={{
            // center nicely
            latitude: 15,
            longitude: -32,
            zoom: 1,
            fitBoundsOptions:{
            }
        }}
        mapLib={maplibregl}
        mapStyle={MAP_STYLES[0].uri}
        maxZoom={13}
        style={{width: width, minWidth: 300, height: height}}
        padding={10}
        attributionControl={false} // use custom
        interactive={true}
        dragRotate={false}
        touchZoomRotate={false}
        touchPitch={false}
        pitchWithRotate={false}
    >
        <NavigationControl
            position="bottom-right"
            showCompass={false}    
        />
        <DrawControl
          position="top-right"
          displayControlsDefault={false}
          ref={drawRef}
          onCreate={onDrawUpdate}
          onUpdate={onDrawUpdate}
          onDelete={onDrawDelete}
          onClear={onClearFeatures}
        />
        <LayerControl defaultPosition="bottom-left"/>
        <Source id={'wof'} type="geojson" data={features}>
            {(OUTLINE_STYLE).map((l) => (
                <Layer key={l.id} {...l}/>
            ))}
        </Source>
    </Map>
    <div className={classes.overlay}>
      <span className={classes.overlayText}>
        Powered by Esri and Maplibre
        {' '}| &copy; <a href="https://geocode.earth/" target="_blank" rel='noreferrer'>Geocode.earth</a>, <a href="https://openstreetmap.org/copyright" target="_blank" rel='noreferrer'>OpenStreetMap</a>, and <a href="https://geocode.earth/guidelines" target="_blank" rel='noreferrer'>others</a>
        {' '}| <a href="https://docs.impactobservatory.com/lulc-maps/lulc-maps.html" target="_blank" rel='noreferrer'>More information</a>
      </span>
    </div>
  </Box>)
}
