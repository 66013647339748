import * as React from "react";

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import DrawRectangle from '@impactobservatory/mapboxgl-draw-rectangle-drag';

import { useControl } from 'react-map-gl';

import { DrawStyles } from "./DrawStyles";
import { extendDrawBar } from './DrawBar';


const CUSTOM_MODES = {
  ...MapboxDraw.modes,
  draw_rectangle: DrawRectangle,
  static: StaticMode,
};

function DrawControl(props, ref) {
  const rectButtonRef = React.useRef();
  const ACTIVE_BUTTON = 'active'; // should match mapbox-gl-draw/constants

  const drawRef = useControl(
    ({map}) => {
      const drawRef = new MapboxDraw({
        ...props,
        modes: CUSTOM_MODES,
        defaultMode: "static",
        styles: DrawStyles
      });
      let drawBar = new extendDrawBar({
        draw: drawRef,
        buttons: [{
          on: "click",
          action: (evt) => {
            evt.preventDefault();
            rectButtonRef.current = evt.target;
            drawRef.deleteAll();
            drawRef.changeMode("draw_rectangle");
            rectButtonRef.current.classList.toggle(ACTIVE_BUTTON);
          },
          classes: ["mapbox-gl-draw_rectangle"],
          title: "Draw rectangle",
        },{
          on: "click",
          action: (evt) => {
            evt.preventDefault();
            drawRef.deleteAll(); // was drawRef.trash();
            rectButtonRef.current?.classList.remove(ACTIVE_BUTTON);
            if(props.onClear) { props.onClear(); }
          },
          classes: ["mapbox-gl-draw_trash"],
          title: "Remove rectangle",
        }
      ]
      });
      return drawBar;
    },
    ({map}) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.delete', props.onDelete);
      if (props.onModeChange) {
        map.on("draw.modechange", props.onModeChange);
      }
    },
    ({map}) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
      if (props.onModeChange) {
        map.off("draw.modechange", props.onModeChange);
      }
    },
    {
      position: props.position
    }
  );

  React.useImperativeHandle(ref, () => drawRef, [drawRef]); 
  // this makes the draw functions available outside the component

  return null;
}

export default React.forwardRef(DrawControl);

