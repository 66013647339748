import * as React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  legendBox: {
    background: "#ECEFF1",
    padding: theme.spacing(2),
    marginTop: "16px !important",
    boxShadow: "none"
  },
  legend: {
    [theme.breakpoints.up("smd")]: {
      columnCount: 2,
      columnFill: "balance-all",
    }
  },
  legendItem: {
    display:"flex",
    alignItems: "center",
    marginBottom: 4,
    height: 24
  },
  legendTitle: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    minHeight: "auto !important",
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0"
    },
  },
  legendClass: {
    width: 24,
    height: 24,
    marginRight: 8,
    display: "inline-block",
    "&.outline": {
      outline: '1px solid #000000',
      outlineOffset: -1
    }
  },
  legendMetaclass: {
    width: 4,
    right: 4,
    display: "inline-block",
    position: "relative"
  }
}));

const LabelOutline = (colorLabel) => {
    // from https://24ways.org/2010/calculating-color-contrast/
    let hexcolor = colorLabel.color.replace('#', '');
    const THRESHOLD = 0.7;
    // tuned to make Rangeland outline but Crops not
  
    // convert short hex to long, so the rgb parsing works
    if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
            return hex + hex;
        }).join('');
    }
  
    var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
      var yiq = ((r*299)+(g*587)+(b*114))/1000;
  
      return (yiq >= THRESHOLD*255) ? "outline" : "";
  }

export const ProductLegend = ({product}) => {
  const classes = useStyles();
  const IS_MOBILE = window ? (window.innerWidth < 600) : false;
  return (
    <Accordion className={classes.legendBox} defaultExpanded={!IS_MOBILE}>
      <AccordionSummary className={classes.legendTitle} expandIcon={<ExpandMoreIcon />}>
        <Typography>{product.classes.length} Classes</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.legend}>
      {product.classes.map((colorlabel, index) => (
        <Box key={`${product.id}-class-${index}`} className={classes.legendItem}>
          {product.metaclasses && product.metaclasses[index] && (
            <span className={classes.legendMetaclass}
              style={{background: product.metaclasses[index].color,
              height: product.metaclasses[index].span*24+(product.metaclasses[index].span-1)*4,
              top: (product.metaclasses[index].span-1)*14
            }} />
          )}
          <span style={{background: colorlabel.color}} className={classes.legendClass+' '+LabelOutline(colorlabel)} />
          <Typography>{colorlabel.name}</Typography>
        </Box>
      ))}
      </AccordionDetails>
    </Accordion>
  )
}