
export const DrawStyles = [
    // styles from https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/EXAMPLES.md 
    // mode == draw_rectangle
    // line stroke
    {
      "id": "gl-draw-line",
      "type": "line",
      "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "draw_rectangle"]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#3BB2D0",
        "line-dasharray": [0.2, 2],
        "line-width": 2
      }
    },
    // polygon fill
    {
      "id": "gl-draw-polygon-fill",
      "type": "fill",
      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "draw_rectangle"]],
      "paint": {
        "fill-color": "#E5F6FD",
        "fill-outline-color": "#E5F6FD",
        "fill-opacity": 0.7
      }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
      "id": "gl-draw-polygon-stroke",
      "type": "line",
      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "draw_rectangle"]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#3BB2D0",
        "line-dasharray": [0.2, 2],
        "line-width": 2
      }
    },
    // vertex point halos
    {
      "id": "gl-draw-polygon-vertex-halo-active",
      "type": "circle",
      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "draw_rectangle"]],
      "paint": {
        "circle-radius": 5,
        "circle-color": "#FFF"
      }
    },
    // vertex points
    {
      "id": "gl-draw-polygon-vertex-active",
      "type": "circle",
      "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "draw_rectangle"]],
      "paint": {
        "circle-radius": 3,
        "circle-color": "#3BB2D0",
      }
    },
  ];
  