import * as React from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RightIcon from '@mui/icons-material/ChevronRight';
import MoreIcon from '@mui/icons-material/MoreHoriz';

import { ProductFeaturesBar, ProductFeaturesList } from "./ProductFeature";
import { ProductLegend } from "./ProductLegend";

import { trackGtagEvent } from "../../utils";

import makeStyles from '@mui/styles/makeStyles';
import yn from "yn";

const ALLOW_BETA_ORDERS = yn(process.env.GATSBY_ALLOW_BETA_ORDERS);

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  cardBottom: {
    padding: '12px 12px 0px 0px !important',
    '& .MuiGrid-item': {
      display: "flex",
    },
    '& hr': {
      margin: '0 16px',
    },
    '& p': {
      display: "flex",
      alignItems: "center",
    }
  },
}));

export default function ProductCard({product, selected, onSelect, handleNext}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const id = product.id;
  
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box key={`${id}-product`} sx={{p: 2}}>
      <Card square sx={{mt: 1}} key={`${product.id}-image`}>
        {product.is_beta && !ALLOW_BETA_ORDERS ? (
          // not clickable, just display title and image
          <CardContent>
            <Box sx={{display: "flex", mb: 1}}>
              <Typography variant="body1">
                {product.product_link ? (
                    <a href={`${product.product_link}`} target="_blank" onClick={() => {
                        trackGtagEvent('select_content', { content_type: "product", item_id: product.id });
                    }}>{product.title}</a>
                ):(product.title)}
              </Typography>
              <Chip label="BETA" color="primary" size="small" sx={{ml: 1}} />
            </Box>
            <CardMedia
              component="img"
              alt={product.description}
              width="200"
              image={product.thumbnail_href}
            />
            <Box className={classes.cardBottom}>
              <ProductFeaturesBar product={product} />
            </Box>
          </CardContent>
        ) : (
          // clickable inside action area, radio button
          <Box onClick={onSelect} sx={{p: 2}}>
            <Box display={"flex"} alignItems={"center"}>
              <FormControlLabel
                label={product.title}
                control={<Radio checked={(selected === id)} disableRipple={true}/>}
                sx={{flexGrow: 1}}
              />
              <IconButton onClick={(evt) => {
                  evt.stopPropagation();
                  evt.preventDefault();
                  onSelect();
                  handleOpen();
                }} disableRipple={true} sx={{"&:hover": {backgroundColor: "rgba(0, 0, 0, 0.04)"}}} title="More">
                <MoreIcon color="primary" style={{border: '2px solid #075E97', borderRadius: '50%'}} />
              </IconButton>
            </Box>
            <CardMedia
              component="img"
              alt={product.description}
              width="200"
              image={product.thumbnail_href}
            />
            <CardContent className={classes.cardBottom}>
              <ProductFeaturesBar product={product} />
            </CardContent>
          </Box>
        )}
        
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"smd"}
        PaperProps={{
          style: {
            borderRadius: 0,
            padding: 24,
          }
        }}
      >
        <Box sx={{mb:1}}>
          <Typography variant="body1" flex={1}>
            <a href={`https://www.impactobservatory.com/${product.id}`}>{product.title}</a>
          </Typography>
          <IconButton aria-label="close"
            onClick={handleClose}
            sx={{position:"absolute", right:8, top:8}}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} smd={8}>
            <CardMedia
              component="img"
              alt={product.description}
              width="500"
              image={product.thumbnail_href}
            />
            <ProductLegend product={product} />
          </Grid>
          <Grid item xs={12} smd={4}>
            <Button variant="contained" size="small" fullWidth sx={{my:1}}
              onClick={() => {
                handleClose();
                onSelect();
                handleNext();
              }}
            >
              Select Your Place <RightIcon />
            </Button>
            <Divider sx={{mb:1}}/>
            <ProductFeaturesList product={product} className={classes.featureList} />
            <Divider sx={{mb: 1}}/>

            <Typography variant="body1">
                {product.description}
            </Typography>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  )
}