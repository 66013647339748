import * as React from "react";

import { Controller } from "react-hook-form";
import dayjs from 'dayjs';
import hash from "object-hash";

import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from "@mui/material/FormLabel";
import makeStyles from '@mui/styles/makeStyles';

import DateRange from "../date/DateRange";
import PlaceInfo from "../place/PlaceInfo";
import PlaceSelect from "../place/PlaceSelect";
import PlaceDisplay from "../place/PlaceDisplay";
import ProductCard from "../product/ProductCard";

import { useIOProducts } from "../../hooks/use-io-products";

import { trackGtagEvent } from "../../utils"

const MIN_AOI_KM2 = parseInt(process.env.GATSBY_MIN_AOI_KM2 || 2);

const SuggestedDate = ({suggestion}) => {
  return (<>
    The <a href="https://docs.impactobservatory.com/release-notes.html" target="_blank" rel="noreferrer">best sub-annual results</a> will use satellite data between {dayjs(suggestion.start_date).format('MMMM D')} and {dayjs(suggestion.end_date).format('MMMM D')} based on your selected area.
  </>)
}

function OrderSteps(props) {
  const { step, formMethods, submitError, handleNext } = props;
  const { control, clearErrors, watch, setError, setValue, trigger, formState: { errors } } = formMethods; // retrieve all form methods
  const { IO_PRODUCTS, IO_PRODUCT_LOOKUP } = useIOProducts();
  
  const selectedProduct = watch("product");
  const selectedPlace = watch("place");
  const suggestedDate = watch("date_suggestion");

  const setGeomProperties = (data) => {
    if(data.properties['geom:area_square_m']) {
      setValue("place.area_km2", data.properties['geom:area_square_m']/1e6)
    }

    if(data.properties['geom:longitude'] && data.properties['geom:latitude']) {
      setValue("place.centroid", [data.properties['geom:longitude'],data.properties['geom:latitude']])
    }
  };

  const onGeometryError = (err) => {
    if (err?.message) {
      setError('place.geojson_url', {message: err.message || 'Unable to geocode place' });
    } else {
      console.error(err)
    }
  }
  
  if (errors.place?.area_km2) {
    trackGtagEvent('exception', {description: errors.place.area_km2.message})
  }

  if(errors.place?.geojson_url) {
    trackGtagEvent('exception', {description: errors.place.geojson_url.message})
  };

  return (
    <>
      <div style={{display: step === 0 ? "block" : "none"}} key={'step-0'}>
        <Grid container>
          <Grid item xs={12} alignItems={'center'} flexDirection={'column'} display={'flex'}>
            <Typography variant="h6">Land Cover Monitoring</Typography>
            <Typography variant="body1" color="textSecondary" sx={{mt: 1}}>
              What type of map would you like to order?
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{flexDirection: {xs: 'column', md: 'row'}}} display={'flex'}>
            <Grid container>
            {IO_PRODUCTS.map((product) => (
            <Grid item xs={12} md={6} key={`PRODUCT-${product.id}`}>
              <ProductCard key={product.id} product={product}
                selected={selectedProduct}
                onSelect={() => {
                  setValue("product", product.id);
                  trackGtagEvent('select_content', { content_type: "product", item_id: product.id });
                }}
                handleNext={handleNext} />
            </Grid>
            ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div style={{display: step === 1 ? "block" : "none"}} key={'step-1'}>
        <Grid container>
          <Grid item xs={12} alignItems={'center'} flexDirection={'column'} display={'flex'}>
            <Typography variant="h6">Area of Interest</Typography>
            <Typography variant="body1" color="textSecondary">
              Where are you interested in monitoring?
            </Typography>
          </Grid>

          <Grid item sm={12} md={6} big={5} lg={4} pt={4}>
            <FormControl>
              <Controller
                name="place"
                control={control}
                rules={{ validate: (value) => (value.geojson_url && value.geojson_url.startsWith('http'))}}
                render={({ field }) => (
                  <PlaceSelect
                    name={field.name}
                    value={field.value}
                    defaultValue={{
                      label: "",
                      geojson_url: ""
                    }}
                    errors={errors.place}
                    clearErrors={clearErrors}
                    onChange={(event) => {
                      field.onChange(event)
                      trackGtagEvent('search', { search_term: event.value });
                    }}
                    onSelect={(selected) => {
                      clearErrors();
                      setValue(field.name, selected);
                      trackGtagEvent('select_content', { content_type: "place", item_id: selected.id });
                    }}
                  />
                )}
              />
            </FormControl>

            <PlaceInfo productTitle={IO_PRODUCT_LOOKUP[selectedProduct]?.title} selectedPlace={selectedPlace} errors={errors} minArea={MIN_AOI_KM2} maxArea={IO_PRODUCT_LOOKUP[selectedProduct]?.aoi_maxsize} />
            
          </Grid>

          <Grid item sm={12} md={6} big={7} lg={8} pt={4}>
            <PlaceDisplay
              width={'100%'}
              height={350}
              clear={selectedPlace.clear}
              geojson_url={selectedPlace.geojson_url}
              bbox={selectedPlace.bbox}
              onLoad={setGeomProperties}
              onClear={() => {
                setValue("place", {});
                clearErrors();
              }}
              onDraw={(customShape) => {
                setValue("place", {
                  geojson_obj: customShape,
                  label: "Custom Shape",
                  slug: hash(customShape.geometry),
                  centroid: customShape.centroid,
                });
                trigger();
              }}
              onError={onGeometryError}
            />
          </Grid>
        </Grid>
      </div>
      <div style={{display: step === 2 ? "block" : "none"}} key={'step-2'}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4} sx={{ml: {lg: 12}, mr: {lg: 4}}}>
            <Typography variant="h6">Date Range</Typography>
            <Typography variant="body1" color="textSecondary">
              What date range would you like to monitor?
            </Typography>
            {selectedProduct === "lulc-14-class" && (
            <Box mt={1} width={"80%"}>
              <Typography variant="body2" color="textSecondary">
                * A 365-day date range is recommended for best results overall.
              </Typography>
              <Typography variant="body2" color="textSecondary" mt={1}>
                Early Access is now available for sub-annual land cover maps.{' '}
                {suggestedDate.end_date && (<SuggestedDate suggestion={suggestedDate} />)}
              </Typography>
            </Box>
            )}
          </Grid>
          <Grid item xs={12} md={5} sx={{mt:{xs: 1, md: 4}, ml:{md: 4}, minWidth: 250}}>
            <FormControl>
              <FormLabel>Select your date range</FormLabel>
              <Controller
                name="date_range"
                control={control}
                render={({ field }) => (
                  <DateRange
                    name={field.name}
                    value={field.value}
                    product={selectedProduct}
                    selectedPlace={selectedPlace}
                    setFormValue={setValue}
                    onChange={(event) => {
                      // actual form field value is changed inside component with setFormValue
                      trackGtagEvent('select_content', { content_type: "date_range", item_id: event.value });
                    }}
                  />
                )}
              />
            </FormControl>

            {submitError && (
              <FormHelperText error={true} sx={{ml: 1}}>{submitError.message || "Unable to submit order"}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default OrderSteps;