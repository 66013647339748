import * as React from "react";
import dayjs from 'dayjs';

import {
  Button,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Radio,
  RadioGroup
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';

import makeStyles from '@mui/styles/makeStyles';

import { dayjsRange, deslug } from '../../utils';
import { useIOProducts } from "../../hooks/use-io-products";

const MOD_API_URL = process.env.GATSBY_MOD_API_URL;

const useStyles = makeStyles((theme) => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  radioChoice: {
    "& .MuiFormControlLabel-label": {
      paddingTop: '9px', // to match MuiRadio root
      paddingBottom: '9px'
    }
  },
  selectedRadio: {
    alignItems: "start",
  }
}));


export default function DateRange(props) {
  const {product, onChange, selectedPlace, setFormValue} = props;
  const { IO_PRODUCT_LOOKUP } = useIOProducts();

  const SELECTED_PRODUCT = IO_PRODUCT_LOOKUP[product] || { date_periods: ["_"] };
  const DEFAULT_CHOICE = SELECTED_PRODUCT.date_periods[0];
  const DATE_MIN = dayjs(SELECTED_PRODUCT.min_date);
  const DATE_MAX = dayjs(SELECTED_PRODUCT.max_date);
  const FULL_YEARS = dayjsRange(DATE_MIN, DATE_MAX, 'year');
  const LAST_FULL_YEAR = FULL_YEARS[FULL_YEARS.length-1];

  const [rangeChoice, setRangeChoice] = React.useState(DEFAULT_CHOICE);
  const [suggestion, setSuggestion] = React.useState({});
  const [customStartDate, setCustomStartDate] = React.useState(DATE_MAX.subtract(...DEFAULT_CHOICE.split('_')));
  const [customEndDate, setCustomEndDate] = React.useState(DATE_MAX);
  const [selectedYear, setSelectedYear] = React.useState(LAST_FULL_YEAR);
  
  const classes = useStyles();

  const setFormValues = (startDate, endDate) => {
    setFormValue("date_range.start_date", startDate.format("YYYY-MM-DD"));
    setFormValue("date_range.end_date", endDate.format("YYYY-MM-DD"));
  }

  const onChangeDate = (endDate, range = rangeChoice) => {
    if (!endDate || !endDate.isValid()) {
      // default to today
      endDate = dayjs();
    }
    // set start_date to (end date - range value)
    let startDate = endDate.subtract(...range.split('_'));

    setCustomEndDate(endDate);
    setCustomStartDate(startDate);
    setFormValues(startDate, endDate);
  };

  const onChangeYear = (year) => {
    setSelectedYear(year);
    // set start_date and end_date to first/last of year
    let yearStart = dayjs().year(year).startOf('year');
    let yearEnd = yearStart.endOf('year');
    setFormValues(yearStart, yearEnd);
  }

  const setDefaultDate = (range = rangeChoice) => {
    if (range === "1_year") {
      onChangeYear(LAST_FULL_YEAR);
    } else if(suggestion.end_date) {
      onChangeDate(dayjs(suggestion.end_date), range);
    } else {
      onChangeDate(DATE_MAX, range);
    }
  }

  React.useEffect(() => {
    setRangeChoice(DEFAULT_CHOICE);
    setDefaultDate(DEFAULT_CHOICE);
    // skip setDefaultDate from the dependency list, it uses react-hook-forms, and should be considered safe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, DEFAULT_CHOICE]);
  
  React.useEffect(() => {
    if (!product || !selectedPlace.centroid) {
      return;
    }

    // get suggestion from API
    fetch(`${MOD_API_URL}/products/${product}/suggestions`,
    {
      headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify({
            "aoi_center": selectedPlace.centroid,
            // TODO, inlude year here
          })
      })
      .then(async(response) => {
        if(response.ok) {
          return await response.json()
        } else if (response.status >= 400) {
          const body = await response.json();
          let messages;
          if (body["detail"]) {
            messages = body["detail"].map((err) => (
              err.loc.join('.')+":"+err.msg
            ))
          } else {
            messages = JSON.stringify(body)
          }
          throw new Error(messages)
        }
      })
      .then(function(data){
        if (data) {
          setSuggestion(data);
          setFormValue("date_suggestion", data);
        }
      })
      .catch(e => {
        console.error(e);
        return false;
      })
    // skip setFormValue from the dependency list, it uses react-hook-forms, and should be considered safe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, selectedPlace.centroid])


  const DateRangeField = ({range}) => {
    if (range === "1_year") {
      return (<TextField
				id="year-select"
				fullWidth
				value={selectedYear}
				label="Select Year"
				variant="filled"
				select
				onChange={(event) => {
					onChangeYear(event.target.value);					
				}}
			>
				{ FULL_YEARS.map((y) => (
					<MenuItem value={y}>{y}</MenuItem>
				))}
			</TextField>)
    } else {
      return (<DatePicker
        key={`${range}-picker`}
        value={customEndDate}
        views={['year','month','day']}
        label={"Select your end date"}
        disableFuture={true}
        minDate={DATE_MIN.add(...rangeChoice.split('_')).format("YYYY-MM-DD")}
        maxDate={DATE_MAX.format("YYYY-MM-DD")}
        renderInput={(params) => <TextField {...params} variant="filled"
          helperText={`${customStartDate.format('MMM D, YYYY')} - ${customEndDate.format('MMM D, YYYY')}`}
        />}
        onChange={onChangeDate}
        components={{
          ActionBar: () => (
            <Stack spacing={2} p={1} direction="row" justifyContent="end">
            <Button onClick={() => {onChangeDate(dayjs())}} variant="outlined">
              Today
            </Button>
            {range === '120_days' && suggestion.end_date && (
            <Button onClick={() => {onChangeDate(dayjs(suggestion.end_date))}} variant="contained">
              Suggested
            </Button>
            )}
            </Stack>
          )
          }}
      />)
    }
  }

  return (
    <RadioGroup
      value={rangeChoice}
      onChange={(event, value) => {
        // reset to defaults for each range when changing
        setRangeChoice(value);
        setDefaultDate(value);
        if(onChange) { onChange(event); }
      }}
    >
      {SELECTED_PRODUCT.date_periods.map((key, index) => {
        let value = deslug(key);
        // rename
        if (value === "1 Year") {
            value = "Annual";
        }
        return (<div key={`${key}-div`} className={classes.radioChoice}>

        <FormControlLabel key={key} value={key} className={rangeChoice === key ? classes.selectedRadio: ''}
          label={<>
            <span>{value+(((DEFAULT_CHOICE === key) && (Object.keys(SELECTED_PRODUCT.date_periods).length > 1)) ? ' *' : '')}</span>
            {(rangeChoice === key) && (
              <DateRangeField range={key} />
            )}
          </>}
          control={<Radio/>}
        />
        </div>)}
      )}
    </RadioGroup>
  )
}
