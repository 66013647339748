import * as React from "react";

import {
    Alert,
    AlertTitle,
    List,
    ListItem,
    Typography,
  } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const SX_SIDEBAR = {
  boxSizing: "border-box",
  px: { xs: 2}, 
  maxWidth: {xs: 320, sm: 300, md: 280, big: 250},
}

const useStyles = makeStyles((theme) => ({
  placeInitial: {
    backgroundColor: "white",
    color: theme.palette.grey["900"],
  },
  placeInfo: {
    boxSizing: "border-box",
    '& li.MuiListItem-root': {
      justifyContent: "space-between",
      padding: theme.spacing(0),
    },
  },
  area_km2: {
    textTransform: "uppercase"
  },
}));

function ReachOut (props) {
  return (<>{' '}
  <a href="https://docs.google.com/forms/d/e/1FAIpQLSdmm-a347XZn-cly8CT7V8KLK9q0WalJA0yyutjnRhNNKGgOw/viewform" target="_blank" rel="noreferrer">
  Reach out to our team</a> to order a custom area.
  </>)
}
  
export default function PlaceInfo(props) {
  const { selectedPlace, errors, minArea, maxArea, productTitle } = props;
  const classes = useStyles();

  if (errors.place?.area_km2) {
    return (
      <Alert severity="error" sx={{...SX_SIDEBAR, mt: 1}}>
        <Typography variant="body2">
          {productTitle} ordered through IO Monitor must be between {minArea.toLocaleString()} and {maxArea.toLocaleString()} square kilometers.
          <ReachOut />
        </Typography>
      </Alert>
    )
  }

  if (errors.place?.area_km2 || errors.place?.geojson_url) {
    return (
      <Alert severity="info" sx={{...SX_SIDEBAR, mt: 1}}>
        <Typography variant="body2">
          Use the draw tool on the map to define your own area of interest.
          <ReachOut />
        </Typography>
      </Alert>
    )
  }
  
  if (selectedPlace && selectedPlace.area_km2) {
    return (
        <Alert icon={false} severity="info" sx={{...SX_SIDEBAR, mt: 2}} className={classes.placeInfo}>
          <AlertTitle sx={{fontWeight: "bold"}}>{selectedPlace.label}</AlertTitle>
          <List>
            <ListItem>
              <Typography variant="body2" className={classes.area_km2}>{selectedPlace.area_km2.toLocaleString()} km2</Typography>
            </ListItem>
          </List>
        </Alert>
    )
  }

  return (
    <Alert icon={false} severity="info" sx={{...SX_SIDEBAR, mt: 2}} className={classes.placeInitial}>
      <AlertTitle>Not finding what you’re searching for?</AlertTitle>
        <Typography variant="body2">
          Try using the filters or the draw tool on the map to define your own area of interest.
          <ReachOut />
        </Typography>
    </Alert>
  )
}