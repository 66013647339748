import * as React from "react";

import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';

import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';

import Markdown from 'markdown-to-jsx'


import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    featureBar: {
      "& .MuiSvgIcon-root": {
        marginRight: 6,
      }
    },
    featureList: {
      '& .MuiListItem-root': {
        paddingLeft: 0,
        "& .MuiSvgIcon-root": {
          marginRight: 10,
        }
      }
    },
    featureListItem: {
      display: "flex",
      alignItems: "center"
    },
  }));

export const ProductFeaturesBar = ({product}) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.featureBar}>
      <Box className={classes.featureListItem}>
        <Typography variant="body2">
          <CategoryOutlinedIcon />{product.classes.length}
        </Typography>
        <Divider orientation="vertical"/>
      </Box>
      <Box className={classes.featureListItem}>
        <Typography variant="body2">
          <AspectRatioIcon />{product.resolution?.replace('resolution', '')}
        </Typography>
        <Divider orientation="vertical"/>
      </Box>
      <Box className={classes.featureListItem}>
        <Typography variant="body2">
        { product.is_beta ? (
            <span>Coming Soon</span>
        ) : (
          <><SellOutlinedIcon /><Markdown>{product.price_description}</Markdown></>
        )}
        </Typography>
      </Box>
    </Grid>
  )
}
  
 export const ProductFeaturesList = ({product}) => {
  const classes = useStyles();
  return (
    <List className={classes.featureList}>
      <ListItem>
        <Typography>
          <SellOutlinedIcon/><Markdown>{product.price_description}</Markdown>
        </Typography>
      </ListItem>
      <ListItem>
        <Typography>
          <CategoryOutlinedIcon/>
          {Object.entries(product.classes.filter((c) => c.name && c.color)).length} Classes
        </Typography>
      </ListItem>
      <ListItem>
        <Typography>
          <AspectRatioIcon/><Markdown>{product.resolution}</Markdown>
        </Typography>
      </ListItem>
      {product.features.map((f, index) => (
        <ListItem>
          <Typography>
            {(f.startsWith('Annual maps') || f.startsWith('Annual or sub-annual maps')) && <TodayOutlinedIcon />}
            {(f.startsWith('Custom date ranges') || f.startsWith('Standard date range')) && <ViewTimelineOutlinedIcon />}
            {f.startsWith('Monitor changes over time') && <ChangeCircleOutlinedIcon />}

            {f}
          </Typography>
        </ListItem>
      ))}
    </List>
  )
 }