import React from "react"
import Layout from "../../sections/Layout"
import Seo from "../../sections/SEO"

import CreateOrder from "../../components/order/CreateOrder"

const OrderPage = () => {
  return (
    <Layout title="Monitor">
        <CreateOrder />
    </Layout>
  )
}

export default OrderPage;

export const Head = () => (
  <Seo />
)