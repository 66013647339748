import * as React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { debounce } from '@mui/material/utils';
import makeStyles from '@mui/styles/makeStyles';
import { createAutocomplete } from "@geocodeearth/core-js";
import { chunkPath, slugify, trackGtagEvent, PlaceTypeDisplayName } from "../../utils";

import PlaceFilter from './PlaceFilter';

const GEOCODE_EARTH_API_KEY = process.env.GATSBY_GEOCODE_EARTH_API_KEY;
const WOF_PLACES_URL = process.env.GATSBY_WOF_PLACES_URL;
const GEOCODE_DELAY_MS = 400;
const FILTER_OPTIONS = ['locality','county','region,dependency','country','disputed'];

const useStyles = makeStyles((theme) => ({
  searchBox: {
    // make this look like a filled input
    background: '#E7E7E7',
    borderRadius: '4px 4px 0 0',
  },
  searchField: {
    // override behavior from MUI
    '& .MuiFilledInput-root': {
      background: 'none',
      paddingRight: '20px !important'
    },
    '& .MuiAutocomplete-endAdornment':{
      top: 'auto',
      right: '0 !important'
    },
  },
  searchFilter: {
    width: 40,
    marginTop: 20
  }
}));

export default function PlaceSelect(props) {
  const [value, setValue] = React.useState(null);
  const [active, setActive] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const activeFilters = Object.keys(filters).filter(k => filters[k])
  const autocompleteService = React.useRef();
  const classes = useStyles();

  const fetchAutocomplete = React.useMemo((text) =>
      debounce(async (text, callback) => (
        autocompleteService.current(text, callback).then(callback)
      ), GEOCODE_DELAY_MS),
      // skip autocompleteService from the dependency list, it gets recursive 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const initAutocomplete = () => {
    autocompleteService.current = createAutocomplete(GEOCODE_EARTH_API_KEY, {
      layers: (activeFilters.length > 0) ? activeFilters : FILTER_OPTIONS // if no filters are active, set to all initial
    });
  };
  React.useEffect(initAutocomplete, [activeFilters])

  const runAutocomplete = () => {
    let localActive = false;
    if (inputValue === '') {
      setActive(false);
      setOptions(value ? [value] : []);
      return undefined;
    } else {
      setActive(true);
      localActive = true
    }

    fetchAutocomplete(inputValue, ({features}) => {
     if (active || localActive) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (features?.length > 0) {
          newOptions = [...newOptions, ...features];
          trackGtagEvent('search', { search_term: inputValue });
        }
        
        setOptions(newOptions);
        setActive(false);
      }
    });

    return () => {
      // cleanup function runs as callback
      setActive(false);
    };
  };
  React.useEffect(runAutocomplete
    // skip autocompleteService from the dependency list, it gets recursive 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [value, inputValue]);

  return (
    <Autocomplete
      id="place-select"
      sx={{ width: { xs: 300, md: 280, big: 250, lg: 250 }}}
      getOptionLabel={(option) =>
        option.properties ? option.properties.label : option
      }
      filterOptions={(options) => (options.filter((x) => x.properties?.source === "whosonfirst"))}
      freeSolo={true}
      options={options}
      autoComplete={true}
      loading={active}
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No places"
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);

        if(newValue) {
          const item = newValue.properties;
          
          const id = item?.source_id || newValue.id; // prefer nested source_id, fallback to root
          if (!id) {
            console.error('invalid place selection', newValue);
            return;
          }
          const path = chunkPath(id, 3, '/');
          props.onSelect({
            "geojson_url": `${WOF_PLACES_URL}/data/${path}/${id}.geojson`,
            "label": item.label,
            "slug": slugify(item.label),
            "id": id,
          });
        } else {
          props.onSelect({clear: true}); // clear parent selection
          if (props.clearErrors) {
            props.clearErrors();
          }
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <Box display={'flex'} alignItems={'center'} p={1} className={classes.searchBox}>
          <TextField {...params} label="Select place" variant="filled"
            className={classes.searchField}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
          <InputAdornment className={classes.searchFilter} position="end">
            <PlaceFilter
              activeFilters={activeFilters}
              initialFilters={FILTER_OPTIONS}
              setFilters={(newFilters) => { setFilters(newFilters); runAutocomplete()}}
            />
          </InputAdornment>
        </Box>
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.properties.id}>
            <Grid container alignItems="left">
              <Grid item>
                  <Box
                    key={option.properties.id}
                    component="span"
                  >
                    <Typography>{option.properties.label}</Typography>
                    <Typography variant="caption">{PlaceTypeDisplayName(option.properties.layer)}</Typography>
                  </Box>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
