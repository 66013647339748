import * as React from "react";

import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FilterListIcon from '@mui/icons-material/FilterList';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";

import makeStyles from '@mui/styles/makeStyles';

import { PlaceTypeDisplayName } from '../../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headline: {
    display: "inline-block"
  },
  button: {
    position: 'absolute',
    right: '5px'
  },
  popover: {
    backgroundColor: theme.palette.grey['100']
  },
  filterCheckbox: {padding: 1}
}));

export default function PlaceFilter({initialFilters, activeFilters, setFilters}) {
    const [anchor, setAnchor] = React.useState(null);
    const classes = useStyles();

    const handleClick = (event) => {
      setAnchor(event.currentTarget);
  };
  
  const handleClose = () => {
      setAnchor(null);
  };
  const open = Boolean(anchor);
  const id = open ? 'filter-popover' : undefined;

  return (<>
    <IconButton
      className={classes.button}
      aria-describedby={id}
      onClick={handleClick}
    >
      <Badge
        badgeContent={activeFilters.length}
        color="primary"
      >
        <FilterListIcon/>
      </Badge>
    </IconButton>

    <Popover
      id={id}
      open={open}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Grid container={true} spacing={1} p={2}
        sx={{ width: { xs: 250, md: 180, big: 250, xl: 250 }}}
        className={classes.popover}
      >
        <Grid item={true} xs={12} className={classes.title}>
          <Typography variant="subtitle2" className={classes.headline}>Filters</Typography>
          {activeFilters.length > 0 ? (
          <Button
            className="filter-clear"
            onClick={() => setFilters({})}
            size="small"
            variant="outlined"
            color="primary"
          >
            {`Clear (${activeFilters.length})`}
          </Button>
          ): (<></>)}
        </Grid>
        <Grid item={true} container={true} xs={12} m={1}>
          {initialFilters.map((key) => {
            const checked = activeFilters.includes(key);
            return (
              <Grid item={true} xs={12} key={key}>              
                <FormControlLabel
                  label={
                    <span className="filter-option">
                      {PlaceTypeDisplayName(key)}
                    </span>
                  }
                  control={
                    <Checkbox
                      className={classes.filterCheckbox}
                      checked={checked}
                      value={true}
                      onChange={(e) => setFilters(val => ({...val, [key]: e.target.checked}))}
                    />
                  }
                />
              </Grid>
              )
            })}
        </Grid>
      </Grid>
    </Popover>
  </>)
}